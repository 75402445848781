tr {
  cursor: pointer;
}

.collapsed-row td {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  height: 60px !important;
  vertical-align: middle !important;
}

.expanded-row td {
  white-space: normal !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.row-toggle {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  user-select: none;
}

/* Hide content when collapsed */
.hidden-content {
  display: none;
}

/* Show content when expanded */
.expanded-row .hidden-content {
  display: block;
}

.no-padding {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

table {
  width: 100% !important;
  table-layout: fixed !important;
}

.table-responsive {
  overflow-x: auto;
}

th, td {
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
  max-width: none !important;
  font-size: 0.9rem;
}

td p {
  margin-bottom: 0.5rem !important;
  font-size: 0.9rem;
}

.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table td {
  min-width: 100px;
}

/* Search and pagination styles */
.search-box {
  max-width: 600px;
}

.pagination {
  margin-bottom: 0;
}

/* Tab navigation styles */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.nav-tabs .nav-link {
  font-weight: 500;
  color: #495057;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
}

.nav-tabs .nav-link:hover, 
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 600;
}

/* Stats container styles */
.stats-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.stats-header {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.stats-header h5 {
  color: #495057;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.stat-card {
  background: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-title {
  color: #6c757d;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.stat-value {
  color: #343a40;
  font-size: 1.5rem;
  font-weight: 700;
}

/* Add button styles */
.header-with-button {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between header and button */
}

.header-with-button h3 {
  margin-bottom: 0;
}

.add-button {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  margin-bottom: 4px; /* Align with header */
}

.table th:first-child,
.table td:first-child {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}

.table .actions-cell {
  width: 100px !important;
  max-width: 100px !important;
  padding: 0.25rem !important;
}

/* Services Modal Styles */
.services-modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.services-list {
  max-height: 400px;
  overflow-y: auto;
}

.services-list .list-group-item {
  transition: background-color 0.2s ease;
  border: none;
}

.services-list .list-group-item:first-child {
  border: none;
}

.services-list .list-group-item:hover {
  background-color: #f8f9fa;
}

.service-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
}

.services-cell {
  cursor: pointer;
  position: relative;
}

.services-cell:hover {
  background-color: rgba(0, 123, 255, 0.05);
}

button.close {
    margin-left: auto !important;
    width: 1.5rem; 
    height: 1.5rem; 
    font-size: 1.25rem;
}

/* Make certain columns smaller */
.table .price-cell, .table .date-cell, .table .status-cell {
  width: 100px !important;
  max-width: 100px !important;
}

@media (min-width: 3000px) {
  .large-screen {
    max-width: 1500px !important;
  }
}

