/* Customer Modal Styles */
.customer-modal {
  margin-top: 70px;
  margin-bottom: 70px;
  max-height: calc(100vh - 140px);
  display: flex;
  align-items: flex-start;
}

.customer-modal .modal-dialog {
  max-height: calc(100vh - 140px);
  margin-top: 0;
  margin-bottom: 0;
}

.customer-modal .modal-content {
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.modal-header-custom {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 1.25rem 1.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-header-custom .modal-title {
  font-weight: 600;
  color: #3c4858;
  font-size: 1.25rem;
}

.modal-body-custom {
  padding: 1.5rem;
  max-height: calc(100vh - 280px); /* 140px for margins + ~140px for header/footer */
  overflow-y: auto;
}

.modal-footer-custom {
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Section Card Styles */
.section-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9ecef;
  padding: 1rem;
  margin-bottom: 1rem;
}

.section-header {
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #f1f3f5;
  padding-bottom: 0.5rem;
}

.section-header h5 {
  font-weight: 600;
  color: #3c4858;
  margin-bottom: 0.1rem;
  font-size: 0.95rem;
}

/* Form Control Styles */
.form-control-modern {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 0.4rem 0.65rem;
  height: calc(1.5em + 0.65rem + 2px);
  font-size: 0.9rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-modern:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
}

.MuiFormGroup-root {
  margin-bottom: 0.5rem !important;
}

.MuiTextField-root {
  margin-bottom: 0.5rem !important;
}

.MuiFormControl-root {
  margin-bottom: 0.5rem !important;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

/* Delete Modal Styles */
.delete-modal .modal-content {
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.delete-modal-header {
  border-bottom: 1px solid #f1f3f5;
  padding: 1rem 1.5rem;
}

.delete-modal-body {
  padding: 1.5rem;
}

.customer-details {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

/* Service Checkboxes Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.75rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  background-color: #f8f9fa;
}

.custom-checkbox {
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  display: flex !important;
  align-items: center;
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.custom-checkbox label {
  display: inline-block;
  margin-bottom: 0;
  cursor: pointer;
}

.custom-checkbox:hover {
  background-color: #f8f9fa;
  border-radius: 4px;
}